import * as React from "react";
import { graphql, HeadFC } from "gatsby";
import Alumnus from "../../components/elements/Alumnus/Alumnus";
import LeftBar from "../../components/elements/LeftBar/LeftBar";
import PageHeader from "../../components/elements/PageHeader/PageHeader";
import PageImage from "../../components/elements/PageImage/PageImage";
import PageInnerHeader from "../../components/elements/PageInnerHeader/PageInnerHeader";
import Tags from "../../components/elements/Tags/Tags";
import Layout from "../../components/layouts/Default/Default";
import { ResponsiveBlock } from "../../components/layouts/ResponsiveBlock/ResponsiveBlock";
import DisclosureElement from "../../components/elements/DisclosureElement/DisclosureElement";
import * as tags from "../../json/tags.json";
import { shuffle } from "../../utils/shuffle/shuffle";
import Hr from "../../components/elements/Hr/Hr";
import H2 from "../../components/elements/H2/H2";
import DivisionAbstruct from "../../components/elements/DivisionAbstruct/DivisionAbstruct";
import SnsShare from "../../components/elements/SnsShare/SnsShare";
import Disclosure from "../../components/elements/Disclosure/Disclosure";
import { CommonHead } from "../../components/commons/Head/Head";

export const query = graphql`
  query {
    allSettingsJson {
      nodes {
        id
        siteTitle
      }
    }
    allMdx(
      filter: {
        frontmatter: {
          isMain: { eq: true }
          category: { eq: "comprehensive" }
          publish: { eq: true }
        }
      }
    ) {
      nodes {
        fields {
          slug
        }
        frontmatter {
          ce_title
          ce_label
          ce_description
          ce_thumbnail
          ce_tags
        }
      }
    }
  }
`;

const IndexPage = ({ data }: any) => {
  const title = data.allSettingsJson?.nodes[0].siteTitle;
  const nodes = data.allMdx?.nodes ?? [];
  return (
    <Layout title={title} pageType="TopPage">
      <div className="tw-c-page-top md:tw-c-page-top-md">
        <PageHeader
          title="英語でつながる仲間と未来"
          leadSentence="授業・海外研修・インターンシップを仲間と楽しむ。英語がわかる、マナーが身につく。"
        />
        <PageImage
          imageSrc="/images/comprehensive/CE-TOP1.jpg"
          sentence1="今を楽しむ"
          sentence2="それが私の力になる"
        />
        <LeftBar>
          <ResponsiveBlock bgColor="">
            <PageInnerHeader
              title="総合英語科の多様なキャラを紹介します"
              imageSrc="/images/comprehensive/CE-TOP2.jpg"
              leadSentence="喜びがわかる。凹みがわかる。生き方がわかる。あなたの共感する人に、きっと出会えます。"
              spaceBottom
            />
            <div className="tw-px-4 tw-py-8 md:tw-px-0 md:tw-py-8">
              <Alumnus nodes={shuffle(nodes).slice(0, 4)} />
            </div>
            <Hr />
            <Tags nodes={nodes} tags={tags.tags} />
          </ResponsiveBlock>
        </LeftBar>
        <DisclosureElement title="総合英語科の学び">
          <Disclosure num="01" title="授業の構成">
            <p className="tw-text-sm md:tw-text-2xl md:tw-leading-9">
              総合英語科の授業は、主に実践的な英語のスキルを学ぶ科目と就職に役立つ社会人スキルを学ぶ科目で構成されています。
            </p>
            <p className="tw-mt-4 tw-text-sm md:tw-text-2xl md:tw-leading-9">
              卒業後の進路は就職（国内および海外）と留学です。社会人として働くにも海外大学で学ぶにも、自分が置かれた環境で自分らしさを発揮しながら適切なコミュニケーションを取ることが大切です。
            </p>
            <p className="tw-mt-4 tw-text-sm md:tw-text-2xl md:tw-leading-9">
              この学科では、自分の個性を活かしながら目標を達成するために、広い意味でのコミュニケーションスキルを身に付けていきます。
            </p>
          </Disclosure>
          <Disclosure num="02" title="英語を学ぶ、海外をめざす">
            <p className="tw-text-sm md:tw-text-2xl md:tw-leading-9">
              英語学習の基本は、「話す・聞く・読む・書く」の英語の4技能をまんべんなく学んで自分の英語力に自信を持つことです。
            </p>
            <p className="tw-mt-4 tw-text-sm md:tw-text-2xl md:tw-leading-9">
              英語力のスタートラインは学生ごとに差がありますから、レベル別のクラスでどこからでも上達できるように工夫をしています。留学をめざす人には異文化コミュニケーション科目や海外大学の入学に必要なTOEFL・IELTSという英語検定の対策科目を用意しています。
            </p>
            <p className="tw-mt-4 tw-text-sm md:tw-text-2xl md:tw-leading-9">
              学生全員が「自分の英語力はここまで伸びた」という達成感を得ること、そのプロセスで「これが自分に合った勉強方法だ」という学習習慣を身に付けることを重視しています。
            </p>
          </Disclosure>
          <Disclosure num="03" title="成功する就職活動">
            <p className="tw-text-sm md:tw-text-2xl md:tw-leading-9">
              就職のための社会スキルは、志望企業から内定を得るための就職対策と、社会に出てから長く役立つ教養科目で身に付けます。
            </p>
            <p className="tw-mt-4 tw-text-sm md:tw-text-2xl md:tw-leading-9">
              就職対策では基礎編として言葉遣いや身だしなみ、ビジネスシーンに合わせた対処を学び、実践編では面接対策や企業インターンシップの機会を設けています。海外就職をめざす人にはアメリカをはじめとして海外での仕事体験も可能です。
            </p>
            <p className="tw-mt-4 tw-text-sm md:tw-text-2xl md:tw-leading-9">
              教養科目は一般知識を学ぶ科目や美しい日本語を学ぶ科目、芸術に触れる文化教養科目、デジタルリテラシーを鍛えるICT科目などがあります。将来仕事が変わってもずっと役に立つスキルをしっかり身に付けます。
            </p>
          </Disclosure>
          <Disclosure num="04" title="生き方と働き方を考える">
            <p className="tw-text-sm md:tw-text-2xl md:tw-leading-9">
              学生には一人ひとりに趣味をはじめとして好きなことがあって、将来の夢もさまざまです。
            </p>
            <p className="tw-mt-4 tw-text-sm md:tw-text-2xl md:tw-leading-9">
              卒業した後の生き方や働き方をじっくり考えることはとても大切で、それには一人で考えるよりも誰かに積極的に相談した方がいいのではないでしょうか。総合英語科は担任制ですので、勉強のこともプライベートもいつでも先生に相談できます。
            </p>
            <p className="tw-mt-4 tw-text-sm md:tw-text-2xl md:tw-leading-9">
              担任以外でもキャリアセンターの先生にも相談できますし、ホームルームや課外活動でクラスメートと色々な話ができます。卒業生の社会人から経験談を聞いたりアドバイスを受けたりもできます。
            </p>
          </Disclosure>
        </DisclosureElement>
        <DivisionAbstruct />
        <Hr />
        <SnsShare path="/comprehensive" title={title} spaceBottom />
      </div>
    </Layout>
  );
};

export default IndexPage;

export const Head: HeadFC = ({ data }: any) => (
  <CommonHead
    ogTitle={`総合英語科 - ${data.allSettingsJson?.nodes[0].siteTitle}`}
    ogDescription="授業・海外研修・インターンシップを仲間と楽しむ。英語がわかる、マナーが身につく。"
    ogImageFilepath="/images/comprehensive/CE-TOP1.jpg"
  >
    <title>総合英語科 - {data.allSettingsJson?.nodes[0].siteTitle}</title>
  </CommonHead>
);
